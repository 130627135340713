import React from "react"
import MainNav from "../components/mainnav"

const OurStoryPage = () => (
  <div className="md:bg-white bg-white text-black">
    <div className="container max-w-full md:static sticky top-0 md:mb-20 mb-8 mx-auto items-center text-center border-b-1 border-sand shadow-2xl">
      <MainNav />
    </div>
    <div className="container overflow-auto lg:flex mx-auto items-center justify-center border-8 border-white rounded-lg shadow-2xl">
      <img
        src="../../Alicia-306-shorter.jpg"
        alt="Main"
        className="object-contain w-1/2 lg:ml-2 mx-auto shadow-2xl border-0 border-white rounded-lg justify-center"
      ></img>
      <div className="lg:pr-10 ml-5">
        <p className="lg:text-left text-center md:text-6xl text-4xl mb-0 font-serif">
          Our Story
        </p>
        <p className="lg:text-left text-center mt-0 text-left text-4xl lg:mb-0 mb-10">
          __
        </p>
        <p className="mb-5 text-lg">
          Yasser and Alicia's love story began over two years ago. One of their
          closest friends, Neda, introduced them over dinner one evening. After
          hours of conversation, Yasser properly asked Alicia out on a date. A
          week later, in Old Town Alexandria, they met at a restaurant on the
          waterfront called Blackwall Hitch. After discovering a mutual love of
          family, culture, and (of course) donuts, it wasn't long before Alicia
          and Yasser began spending all their time together.
        </p>
        <p className="my-5 text-lg">
          The rest, as they say, is history. Yasser not only fell in love with
          Alicia, but also with Peanut & Mooki. Soon, they began traveling and
          doing many food marathons across cities - eating countless donuts,
          burgers, and fries. On one particular trip to Florida for
          Thanksgiving, Yasser popped the question on the beach, at sunset, with
          family all around. Of course Alicia said yes.
        </p>
        <p className="my-5 text-lg">
          We can’t wait for this exciting new chapter to begin and we can't wait
          to celebrate our wedding day with you!
        </p>
      </div>
    </div>
    <div className="md:grid md:grid-flow-row-dense md:grid-cols-3 grid-cols-1 md:grid-rows-2 mt-20 gap-0">
      {/* Row 1 (engagement photos) */}
      <img
        src="../../Alicia-68.jpg"
        alt="Grid Top Left"
        className="container w-full max-h-full mx-auto"
      ></img>
      <img
        src="../../Alicia-140.jpg"
        alt="Grid Top Middle"
        className="container w-full max-h-full mx-auto"
      ></img>
      <img
        src="../../Alicia-169.jpg"
        alt="Grid Top Right"
        className="container w-full max-h-full mx-auto"
      ></img>
      {/* Row 2 (engagement photos) */}
      <img
        src="../../Alicia-377.jpg"
        alt="Grid Top Left"
        className="container w-full max-h-full mx-auto"
      ></img>
      <img
        src="../../Alicia-93.jpg"
        alt="Grid Top Middle"
        className="container w-full max-h-full mx-auto"
      ></img>
      <img
        src="../../Alicia-77.jpg"
        alt="Grid Top Right"
        className="container w-full max-h-full mx-auto"
      ></img>
      {/* Row 3 (engagement photos) */}
      <img
        src="../../Alicia-6.jpg"
        alt="Grid Top Left"
        className="container w-full max-h-full mx-auto"
      ></img>
      <img
        src="../../Alicia-33.jpg"
        alt="Grid Top Middle"
        className="container w-full max-h-full mx-auto"
      ></img>
      <img
        src="../../Alicia-135.jpg"
        alt="Grid Top Right"
        className="container w-full max-h-full mx-auto"
      ></img>

      {/* Row 4 (non-engagement photos) */}
      <img
        src="../../denver.jpg"
        alt="Grid Top Left"
        className="container w-full max-h-full mx-auto"
      ></img>
      <img
        src="../../fava-pot.jpg"
        alt="Grid Top Middle"
        className="container w-full max-h-full mx-auto"
      ></img>
      <img
        src="../../denver-2.jpg"
        alt="Grid Top Right"
        className="container w-full max-h-full mx-auto"
      ></img>
      {/* Row 5 (non-engagement photos) */}
      <img
        src="../../florida.jpg"
        alt="Grid Bottom Left"
        className="container w-full max-h-full mx-auto"
      ></img>
      <img
        src="../../header-photo-3.jpg"
        alt="Grid Bottom Middle"
        className="container w-full max-h-full mx-auto"
      ></img>
      <img
        src=".././kentucky.jpg"
        alt="Grid Bottom Right"
        className="container w-full max-h-full mx-auto"
      ></img>
    </div>
  </div>
)

export default OurStoryPage
